.listContainer {
  margin: 20px auto;
  width: 80%;
}

.bookList {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 80px;
  flex-wrap: wrap;
}

.titleStyling {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btnStyle {
  width: 150px;
  padding: 1rem 0;
  cursor: pointer;
  font-size: 1rem;
}
