@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  background-color: #f1f1f1;
}
