.formContainer {
  height: 550px;
  width: 30%;
  margin: 50px auto;
  background-color: white;
  box-shadow: 5px 5px 15px grey;
  padding: 7px 10px;
}

.inputField {
  height: 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputField label {
  font-size: 1.2rem;
}

.inputField input {
  height: 30px;
  font-size: 1.1rem;
}

.inputField input:focus {
  outline: none;
}

.description {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.description label {
  font-size: 1.2rem;
}

.description textarea {
  height: 150px;
  font-size: 1.1rem;
}

.description textarea:focus {
  outline: none;
}

.btn {
  height: 35px;
  width: 150px;
  background-color: #5cb85c;
  border-style: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  letter-spacing: 1px;
  margin: 25px 0 0 0;
}

.btn:hover {
  background-color: #3fb770;
}
